/**
* Generated automatically at built-time (2025-01-02T07:25:12.192Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "ulkoilmakeittion-kokoelman-yleiskatsaus",templateKey: "sites/106-61c33e6f-113f-428b-a626-5b55f9594bd5"};